/* articles */
.articles {
  float: right;
  width: 585px;
  margin-bottom: 30px;
  @media (max-width: 1199px) {
    width: 560px;
    padding: 0 10px;
  }
  @media (max-width: 1024px) {
    float: left;
    margin-bottom: 50px;
  }
  @media (max-width: 767px) {
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    margin-bottom: 20px;
  }
  .articles__inner {
    display: flex;
    justify-content: space-between;
    @media (max-width: 767px) {
      display: block;
    }
  }
  .articles__item {
    display: block;
    width: 280px;
    margin-bottom: 25px;
    background: #ffffff;
    box-shadow: 0 1px 1px 1px rgba(0,0,0,.15);
    &:hover {
      .articles__ttl,
      .articles__text {
        color: #dd5858;
      }
      .articles__pic {
        transform: scale(1.03);
      }
    }
    @media (max-width: 1199px) {
      width: 270px;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  .articles__pic-wrap {
    overflow: hidden;
  }
  .articles__pic {
    width: 100%;
    height: 223px;
    background-size: cover;
    background-repeat: no-repeat;
    transition: transform .4s;
  }
  .articles__descr {
    padding: 20px 23px;
  }
  .articles__ttl {
    margin-top: 0;
    font-size: 18px;
    line-height: 1;
    color: #374045;
    font-weight: bold;
    transition: color .15s;
  }
  .articles__text {
    font-size: 14px;
    line-height: 1.5;
    color: #4f5558;
    transition: color .15s;
  }
  .articles__bottom {
    text-align: right;
    @media (max-width: 1024px) {
      text-align: left;
    }
  }
  .articles__link {
    padding: 0 0 5px 22px;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAASCAYAAABrXO8xAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjAxRTIxOTJFRTkzNjExRTVCNTNGODdGOUVGQjIyQkU1IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjAxRTIxOTJGRTkzNjExRTVCNTNGODdGOUVGQjIyQkU1Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MDFFMjE5MkNFOTM2MTFFNUI1M0Y4N0Y5RUZCMjJCRTUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MDFFMjE5MkRFOTM2MTFFNUI1M0Y4N0Y5RUZCMjJCRTUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5+iydrAAAAm0lEQVR42mJkWDv7GwMDAycDaeA7C1QTI4ka/zMxkAmQNX4CmQTFn6BiD5HEQPgpTDELkkZeJCf/h9LyxNiIDdxHs/ERNhuxAUVybXyEZuMzYm2UI8bGb0gmf4GKPUCz8TE2G7mxGKxArh+JSgDP0JyFDWN1qgoQsxOw6Cc2jXeBWIKARpCrpNE1SpLrR5IAyMbvSIma6IwMEGAAVsIwxGuG7mQAAAAASUVORK5CYII=') 0 2px no-repeat;
    .articles__link__text {
      color: #33ab9a;
      border-bottom: 1px solid #aad6d0;
    }
    &:hover {
      .articles__link__text {
        color: #dd5858;
        border-bottom: 1px solid #e5a5a5;
      }
    }
  }
}
