.mobile-nav {
  //   display: none;
  background: #43b5a5;
  padding-top: 75px;
  .mobile-nav__item {
    &:first-child {
      .mobile-nav__link {
        border-top: none;
      }
    }
    &._active {
      .mobile-nav__link {
        background: #39998b;
        border-top: transparent;
      }
      & + .mobile-nav__item {
        .mobile-nav__link {
          border-top: transparent;
        }
      }
    }
  }
  .drop-item {
    &._open {
      .mobile-nav__link {
        &:after {
          transform: rotate(-45deg);
        }
      }
    }
    .mobile-nav__link {
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 20px;
        width: 4px;
        height: 4px;
        margin-top: -4px;
        border: solid #ffffff;
        border-width: 0 0 2px 2px;
        transform: rotate(-135deg);
        transition: border-color 0.15s, transform 0.3s;
      }
    }
  }
  .mobile-nav__link {
    position: relative;
    display: block;
    height: 29px;
    padding: 0 35px 0 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    font-size: 12px;
    line-height: 29px;
    text-transform: uppercase;
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 1px;
  }
  .mobile-nav-drop {
    display: none;
    background: #ffffff;
  }
  .mobile-nav-drop__item {
    &._active {
      .mobile-nav-drop__link {
        background: #f1f1f1;
        border-top: transparent;
      }
      & + .mobile-nav-drop__item {
        .mobile-nav-drop__link {
          border-top: transparent;
        }
      }
    }
  }
  .mobile-nav-drop__link {
    display: block;
    padding: 2px 10px 2px 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    color: #34434c;
  }
}

.slideout-menu {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  width: 200px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.slideout-panel {
  position: relative;
  z-index: 1;
  //will-change: transform;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden;
}

.slideout-open .slideout-menu {
  opacity: 1;
  visibility: visible;
}

.fixed {
  backface-visibility: hidden;
  position: fixed;
  z-index: 2;
  transition: transform 300ms ease;
}

.fixed-open {
  transform: translate3d(256px, 0px, 0px);
}

@media screen and (min-width: 767px) {
  .slideout-open .slideout-menu {
    display: none;
  }
}
