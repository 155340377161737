/* head */
.head {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  box-shadow: 0 1px 1px 1px rgba(0,0,0,.1);
  z-index: 5;

  .head__inner {
    position: relative;
    padding-left: 303px;
    box-sizing: border-box;
    max-width: 1200px;
    margin: 0 auto;
    @media (max-width: 1024px) {
      padding-left: 175px;
    }
    @media (max-width: 767px) {
      height: 50px;
    }
  }

  .head__nav-btn {
    display: none;
    @media (max-width: 767px) {
      display: block;
      padding: 5px 10px;
      position: absolute;
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
      font-size: 23px;
      color: #547c83;
    }
  }

  .head__logo {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    line-height: 1;
    @media (max-width: 767px) {
      left: 75px;
    }
  }

  .head__logo-pic {
    max-width: 100%;
    @media (max-width: 1199px) {
      margin-left: 15px;
    }
    @media (max-width: 1024px) {
      width: 160px;
    }
  }

  .head__nav-list {
    font-size: 0;
    @media (max-width: 1199px) {
      text-align: center;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }

  .head__nav-item {
    position: relative;
    display: inline-block;
    &.drop-item {
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 20px;
        width: 4px;
        height: 4px;
        margin-top: -4px;
        border: solid #b9c9d3;
        border-width: 0 0 2px 2px;
        transform: rotate(-45deg);
        transition: border-color .15s;
      }
      .head__nav-link {
        padding-right: 35px;
        &:before {
          right: 35px;
          background: transparent;
        }
      }
      &:hover {
        .head__nav-drop {
          opacity: 1;
          visibility: visible;
        }
        .head__nav-link {
          &:before {
            background: transparent;
          }
        }
        &:after {
          border-color: #33ab9a;
        }
      }
    }
    &._active {
      .head__nav-link {
        color: #33ab9a;
        &:before {
          background: #33ab9a;
        }
      }
      &:after {
        border-color: #33ab9a;
      }
    }
    &:hover {
      .head__nav-link {
        color: #33ab9a;
        &:before {
          background: #33ab9a;
        }
      }
    }
  }

  .head__nav-link {
    position: relative;
    display: inline-block;
    padding: 30px 25px 25px;
    font-size: 16px;
    line-height: 1;
    font-weight: bold;
    letter-spacing: 1px;
    color: #414b50;
    text-transform: uppercase;
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 25px;
      right: 25px;
      height: 5px;
      transition: background .15s;
    }
    @media (max-width: 1199px) {
      padding: 30px 14px 25px;
      font-size: 15px;
      &:before {
        left: 14px;
        right: 14px;
      }
    }
    @media (max-width: 1024px) {
      padding: 22px 10px 17px;
      font-size: 12px;
      &:before {
        left: 13px;
        right: 13px;
      }
    }
  }

  .head__nav-drop {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 0;
    right: 0;
    //bottom: -144px;
    background: #fff;
    transition: opacity .15s;
    box-shadow: inset 0 1px 2px rgba(0,0,0,.25);
    @media (max-width: 1199px) {
      //bottom: -136px;
    }
    @media (max-width: 1024px) {
      //bottom: -128px;
    }
    .head__nav-drop__link {
      display: block;
      padding: 7px 20px 9px;
      font-size: 20px;
      line-height: 1;
      color: #4f5558;
      transition: background .15s;
      text-align: left;
      &:hover {
        background: #ececec;
      }
      @media (max-width: 1199px) {
        font-size: 18px;
      }
      @media (max-width: 1024px) {
        font-size: 16px;
      }
    }
  }

}





