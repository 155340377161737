/* pricing */
.pricing {
  margin-right: 120px;
  @media (max-width: 1199px) {
    margin-right: 5%;
    overflow: hidden;
  }
  @media (max-width: 767px) {
    margin-right: 0;
  }
  .pricing__ttl {
    margin-top: 0;
    @media (max-width: 767px) {
      font-size: 20px;
    }
  }
  .pricing__table-wrap {
    padding-bottom: 15px;
    overflow-x: auto;
  }
  .pricing__show-more {
    position: relative;
    width: 80px;
    padding: 5px 30px 5px 16px;
    margin: 0 auto;
    text-align: center;
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      top: 16px;
      right: 100%;
      width: 324px;
      border-bottom: 2px solid #cae6e5;
    }
    &:after {
      content: '';
      position: absolute;
      top: 16px;
      left: 100%;
      width: 324px;
      border-bottom: 2px solid #cae6e5;
    }
    &:hover {
      .pricing__show-more__text {
        color: #dd5858;
        border-bottom-color: #e99e9f;
        &:before {
          border-color: #dd5858;
        }
      }
    }
    .pricing__show-more__text {
      color: #33ab9a;
      border-bottom: 1px dotted #aad6d0;
      transition: color 0.15s, border-color 0.15s;
      &:before {
        content: '';
        position: absolute;
        width: 6px;
        height: 6px;
        border: solid #50b6a8;
        border-width: 0 1px 1px 0;
        top: 11px;
        right: 17px;
        transform: rotate(45deg);
        transition: border-color 0.15s;
      }
    }
  }
  .pricing__note {
    position: relative;
    padding-left: 30px;
    margin-top: 20px;
    font-style: italic;
    &:before {
      content: '';
      position: absolute;
      top: 2px;
      left: 0;
      width: 20px;
      height: 15px;
      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjc5MDUxMDk3RjM1OTExRTVCMEVEQkFGNEYyNzhDQTA0IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjc5MDUxMDk4RjM1OTExRTVCMEVEQkFGNEYyNzhDQTA0Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NzkwNTEwOTVGMzU5MTFFNUIwRURCQUY0RjI3OENBMDQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NzkwNTEwOTZGMzU5MTFFNUIwRURCQUY0RjI3OENBMDQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7HzEYZAAABQ0lEQVR42qTUuUoDURTG8ZnBwggK2ogLCFbi8gbuNoJiZePCCBYuvVqIYqHihpZ2Ri0sbRRMJS7voLWleQBxS9D/gW/gghOdmAM/CPcmH+du8XNhuOx53irKvdLqDes+ga98eMR2iYGLaC9TZ/UYxCxyRQb52EczUoEGl/CJS1QWEWYNnaARCzYQBeYxh3vcoi5BWArn2rsxfLiBUW3hQMFtv4RVIYMHNZKPJoKYL59hRsvvjZmvxQ0uYDfky50MCnRgP5hUF+POeJO6z2glP6pQYAMOsavrsIdpXOMYE+hJGtiBO93LNXTiGa0Y1fgw0hiJO3a3+nGEKS3N6kX3zC07jAFcoRqncYEhVjCkl/NXPaFP+1mDrBs4r3vVreUlraxC7cQrokC7mC16LV3/fMe2TTuWZYGbWmq6xD+Hd2x8CzAAbMlCjMU5TgoAAAAASUVORK5CYII=')
        no-repeat;
    }
  }
  table {
    min-width: 600px;
    th {
      padding: 5px 18px 5px 37px;
      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkY3NTI3NzcxRjM1NzExRTU5RUIxQ0JGQ0FGM0QzQkY3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkY3NTI3NzcyRjM1NzExRTU5RUIxQ0JGQ0FGM0QzQkY3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6Rjc1Mjc3NkZGMzU3MTFFNTlFQjFDQkZDQUYzRDNCRjciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6Rjc1Mjc3NzBGMzU3MTFFNTlFQjFDQkZDQUYzRDNCRjciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5EydmyAAAA50lEQVR42oTRPWoCURDA8VW2ioqgWEiw10o7RbDwI0UWBBsrC3OLFJaS3MHCI9gISlbQBCwkWKiI4A0EUXMBCf4fzIOnrDjwK3aYmTfM+uaLpWVEEp8oyvcELWx1gW0UpzBDEN+Sq6GMnG7yGw0fCMHBi3Ak19ZFZkMea4yMnCuTs14NByTwZOQCeMbRq6GDCPqoiCHC6Ooin3GlOFaIWdexRwY780pqxy+Zpk45lXxBTrzBK35t2duVghJ+bl5QZ+1hgLRffoya3PQoVjHGG6J4t+XWZzTEvfhHVTX8yVp163GcLgIMAP7fKwVUyiVFAAAAAElFTkSuQmCC')
        10px 50% no-repeat;
      &:first-child {
        background: none;
      }
    }
  }
}
